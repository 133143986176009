//Import just for async polyfill load
import 'core-js/es/promise';
import '@ungap/global-this';

//Main app import
function runApp() {
  if (!window.ResizeObserver)
    import(/* webpackChunkName: "polyfillObserver" */'./polyfillObserver').then(module => {
      import(/* webpackChunkName: "appEntry" */'./appEntry');
    });
  else
    import(/* webpackChunkName: "appEntry" */'./appEntry');
}

//Load polyfill for ES6 support
if (typeof Symbol === 'undefined') {
  import(/* webpackChunkName: "polyfill" */'./polyfill').then(() => {
    runApp();
  });
} else {
  runApp();
}